import {Button, Form, Select} from "antd";
import React from "react";
import {DEFAULT_LANGUAGES, SOURCE_LANGUAGES} from "../settingValues";

const SourceLanguagesSelection = ({orgSourceLanguages, updateSourceLanguages, setFormChanged, updateTargetLanguages, translate}) => {
    return (
        <Form.Item
            label="Source Languages" /* name="sourceLanguages" */
        >
            <Select
                value={orgSourceLanguages}
                mode="multiple"
                placeholder="Select source languages"
                style={{ width: '100%' }}
                onChange={(s) => {
                    updateSourceLanguages(s);
                    setFormChanged(true);
                }}
                optionFilterProp="label"
            >
                {SOURCE_LANGUAGES.sort((o1, o2) => {
                    if (o1 === 'auto') return -1;
                    else if (o2 === 'auto') return 1;
                    return translate(o1).localeCompare(translate(o2));
                }).map((item, i) => (
                    <Select.Option
                        key={i}
                        value={item}
                        disabled={!DEFAULT_LANGUAGES.includes(item)}
                        label={translate(item)}
                    >
                        {translate(item)}
                    </Select.Option>
                ))}
            </Select>
            <Button
                onClick={() => {
                    updateSourceLanguages([]);
                    setFormChanged(true);
                }}
            >
                Clear All
            </Button>
            <Button
                onClick={() => {
                    updateTargetLanguages(
                        orgSourceLanguages.filter((val) => val !== 'auto')
                    );
                    setFormChanged(true);
                }}
            >
                Copy To Target
            </Button>
            <Button
                onClick={() => {
                    updateSourceLanguages(
                        SOURCE_LANGUAGES.filter((e) =>
                            DEFAULT_LANGUAGES.includes(e)
                        )
                    );
                    setFormChanged(true);
                }}
            >
                Default Languages
            </Button>
        </Form.Item>
    );
};

export default SourceLanguagesSelection;