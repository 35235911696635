import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout, setAppLanguage } from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './style.css';
import '../DropMenu/style.css';
import onClickOutside from 'react-onclickoutside';
import DropdownComponent from '../DropDownComponent';
import HeaderButton from '../HeaderButton';
import QuickLinks from './quickLinks';

class Header extends Component {
  state = {
    selectLanguage: this.props.store.globalOptions.appLanguage,
    userMenu: false,
    languageMenu: false,
    languageHover: false,
  };

  localizations = Object.keys(this.props.i18n.store.data);

  componentDidMount() {
    this.setSelectLanguage(this.props.store.globalOptions.appLanguage);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.store.user.organization !==
        this.props.store.user.organization
    ) {
      this.setState(() =>
        this.setSelectLanguage(this.props.store.globalOptions.appLanguage)
      );
    }
  }

  handleClickOutside = () => {
    this.closeUserMenu();
    this.closeLangMenu();
  };

  languageHover = () => {
    this.setState({ languageHover: true });
  };
  languageMouseLeave = () => {
    this.setState({ languageHover: false });
  };

  openUserMenu = () => {
    this.setState({ userMenu: !this.state.userMenu });
  };

  closeUserMenu = () => {
    this.setState({ userMenu: false });
  };

  openLangMenu = () => {
    this.setState({ languageMenu: !this.state.languageMenu });
  };

  closeLangMenu = () => {
    this.setState({ languageMenu: false });
  };

  setSelectLanguage = (item) => {
    this.setState({ selectLanguage: item });
    this.props.i18n.changeLanguage(item);
  };

  getUserString = () => {
    if (!this.props.menuLabel) {
      return this.props.store.user.email.substring(
        0,
        this.props.store.user.email.indexOf('@')
      );
    } else {
      if ({}.constructor !== this.props.menuLabel.constructor) {
        if (
          this.props.menuLabel.toLowerCase() === 'email' ||
          this.props.menuLabel.toLowerCase() === 'username'
        ) {
          return this.props.store.user.email.substring(
            0,
            this.props.store.user.email.indexOf('@')
          );
        } else {
          return this.props.menuLabel;
        }
      } else {
        if (!!this.props.menuLabel[this.props.i18n.language.substring(0, 2)]) {
          return this.props.menuLabel[this.props.i18n.language.substring(0, 2)];
        } else {
          return this.props.t('menuLabel');
        }
      }
    }
  };

  localizationCodeToLabel = (el) => {
    switch (el) {
      case 'en':
        return 'English';
      case 'ca':
        return 'Català';
      case 'es':
        return 'Español';
      case 'fr':
        return 'Français FR';
      case 'fr-CA':
        return 'Français (Canadien)';
      case 'de':
        return 'Deutsch';
      case 'zh-CN':
        return '中文（简体)';
      case 'ja':
        return '日本語';
      case 'ko':
        return '한국어';
      case 'sv':
        return 'Svenska';
      case 'da':
        return 'Dansk';
      case 'fi':
        return 'Suomi';
      case 'no':
        return 'Norsk';
      default:
        return 'English';
    }
  };

  displaySortedOptions = () => {
    let langs = [];
    this.props.store.user.organization.uiAssets.localizations.map(
        (el, i) => {
          let l = this.localizationCodeToLabel(el);
          langs.push({
            label: l,
            value: i,
            lang: el,
            isActive: el === this.props.i18n.language,
          });
        }
    )
    return langs.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) //sort labels alphabetically
  };

  burgerMenuItems = () =>
    this.props.store.user.organization.uiAssets.burgerMenuItems
      ? this.props.store.user.organization.uiAssets.burgerMenuItems.map(
          (bMenuItem, i) => {
            return {
              label: bMenuItem.label,
              action: () => window.open(bMenuItem.link, '_blank'),
              value: 'custom-burgermenu-item-' + i,
            };
          }
        )
      : [];

  render() {
    return (
      <>
        <div>
          <header>
            <div
              role="banner"
              className="headerDiv"
              style={{
                background:
                  this.props.store.user.organization.uiAssets.headerBackground,
              }}
              onClick={() => {
                if (this.state.userMenu) this.setState({ userMenu: false });
                if (this.state.languageMenu)
                  this.setState({ languageMenu: false });
              }}
            >
              <div
                className="logoHeader"
                onClick={() => {
                  this.props.history.push('/');
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <img
                  className="imgHeader"
                  alt="organization logo"
                  src={this.props.store.user.organization.uiAssets.headerLogo}
                />
                {this.props.store.user.organization.name === 'uoc' ? (
                  <h1 className="header-title-h1">
                    {this.props.t('headerH1')}
                  </h1>
                ) : (
                  <h1 className="header-title-h1">
                    {this.props.store.user.organization.uiAssets.headerTitle}
                  </h1>
                )}
              </div>
                <>
                  <div className="header-button-container">
                      <React.Fragment>
                        <HeaderButton
                            isDropdownVisible={this.props.auth.isAuthenticated()}
                          lang={this.props.i18n.language}
                          orgName={this.props.store.user.organization.name}
                          buttonName={this.props.t('FAQ')}
                          colors={{
                            textColor:
                              this.props.store.user.organization.uiAssets
                                .dropdownTitleColor,
                          }}
                        />
                          <DropdownComponent
                              isDropdownVisible={true} //this dropdown needs to be always visible
                            id={'dropdownWebsiteLanguage'}
                            defaultValue={0}
                            colors={{
                              selected:
                                this.props.store.user.organization.uiAssets
                                  .dropdownSelected,
                              hover:
                                this.props.store.user.organization.uiAssets
                                  .dropdownHover,
                              textColor:
                                this.props.store.user.organization.uiAssets
                                  .dropdownTitleColor,
                            }}
                            options={this.displaySortedOptions()}
                            onSelect={(o) => {
                              this.setSelectLanguage(o.lang);
                              this.props.setAppLanguage(o.lang); //saving engines in globalOptions
                            }}
                          />
                      </React.Fragment>

                    {this.props.store.user.email && (
                      <DropdownComponent
                          isDropdownVisible={this.props.auth.isAuthenticated()}
                        id={'dropdownUserSetting'}
                        //defaultValue={0}
                        colors={{
                          selected:
                            this.props.store.user.organization.uiAssets
                              .dropdownSelected,
                          hover:
                            this.props.store.user.organization.uiAssets
                              .dropdownHover,
                          textColor:
                            this.props.store.user.organization.uiAssets
                              .dropdownTitleColor,
                        }}
                        user={this.getUserString()}
                        options={[
                          {
                            label: `${this.props.t('headerVersion')} ${
                              process.env.REACT_APP_VERSION
                            }`,
                            action: () => {},
                            value: 0,
                          },
                          ...this.burgerMenuItems(),
                          (this.props.roleId === '99' || this.props.roleId === '98') &&
                            (!window.location.pathname.startsWith(
                              '/usermanagement'
                            )
                              ? {
                                  label: 'User Management',
                                  action: () =>
                                    this.props.history.push('/usermanagement'),
                                }
                              : {
                                  label: 'GLAI Portal',
                                  action: () => this.props.history.push('/'),
                                }),
                          {
                            label: this.props.t('headerLogout'),
                            action: () => this.props.history.push('/logout'), //check about logout function
                            value: 1,
                            logoutItem: true,
                          },
                        ]}
                        onSelect={(o) => {
                          o.action();
                        }}
                      />
                    )}
                  </div>
                </>
            </div>
            {!!this.props.store.user.email &&
              !!this.props.quickLinks &&
              !!this.props.auth.isAuthenticated() && (
                <QuickLinks
                  quickLinks={this.props.quickLinks}
                  quickLinksBackground={this.props.quickLinksBackground}
                  quickLinksForeground={this.props.quickLinksForeground}
                />
              )}
          </header>
          <div
            className="headerMargin"
            style={
              this.props.quickLinks && {
                height: '6em',
              }
            }
          ></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  quickLinks: state.user.organization.uiAssets.quickLinks,
  quickLinksBackground: state.user.organization.uiAssets.quickLinksBackground,
  quickLinksForeground: state.user.organization.uiAssets.quickLinksForeground,
  menuLabel: state.user.organization.uiAssets.menuLabel,
  roleId: state.user.roleid,
});

const mapDispatchToProp = (dispatch) => ({
  logout: () => dispatch(logout()),
  setAppLanguage: (appLanguage) => dispatch(setAppLanguage(appLanguage))
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProp
  )(withRouter(onClickOutside(Header)))
);
