import {Button, Form, Select} from "antd";
import React from "react";
import {DEFAULT_LANGUAGES, TARGET_LANGUAGES} from "../settingValues";

const TargetLanguagesSelection = ({orgTargetLanguages, updateSourceLanguages, setFormChanged, updateTargetLanguages, translate}) => {
    return (
        <Form.Item
            label="Target Languages"
            //name="targetLanguages"
            /* initialValue={
              isNew ? DEFAULT_LANGUAGES : orgTargetLanguages
            } */
        >
            <Select
                mode="multiple"
                value={orgTargetLanguages}
                placeholder="Select target languages"
                style={{ width: '100%' }}
                onChange={(t) => {
                    updateTargetLanguages(t);
                    setFormChanged(true);
                }}
                optionFilterProp="label"
            >
                {TARGET_LANGUAGES.sort((o1, o2) => {
                    return translate(o1).localeCompare(translate(o2));
                }).map((item, i) => (
                    <Select.Option
                        key={i}
                        value={item}
                        disabled={!DEFAULT_LANGUAGES.includes(item)}
                        label={translate(item)}
                    >
                        {translate(item)}
                    </Select.Option>
                ))}
            </Select>
            <Button
                onClick={() => {
                    updateTargetLanguages([]);
                    setFormChanged(true);
                }}
            >
                Clear All
            </Button>
            <Button
                onClick={() => {
                    updateSourceLanguages(orgTargetLanguages);
                    setFormChanged(true);
                }}
            >
                Copy To Source
            </Button>
            <Button
                onClick={() => {
                    updateTargetLanguages(
                        TARGET_LANGUAGES.filter((e) =>
                            DEFAULT_LANGUAGES.includes(e)
                        )
                    );
                    setFormChanged(true);
                }}
            >
                Default Languages
            </Button>
        </Form.Item>

    );
};

export default TargetLanguagesSelection;